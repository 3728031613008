<template>
  <div class="flix-row">
    <div class="flix-col-md-12">
      <div class="flix-form-group">
        <div class="flix-alert flix-alert-info">{{ $t('message.imprintWarning') }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
